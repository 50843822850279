/* odibee-sans-regular - latin */
@font-face {
    font-family: 'Odibee Sans';
    font-weight: $font-weight-normal;
    src: url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
         url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.woff2') format('woff2'),
         url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.woff') format('woff'),
         url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.ttf') format('truetype'),
         url('../src/styles/fonts/odibee-sans-v14-latin/odibee-sans-v14-latin-regular.svg#OdibeeSans') format('svg');
         
}

@font-face {
    font-family: 'DS-Digtal';
    font-weight: $font-weight-normal;
    src: url('../src/styles/fonts/ds_digital/Digital-Numbers.ttf.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../src/styles/fonts/ds_digital/digital-Numbers.ttf.eot?#iefix') format('embedded-opentype'),
         url('../src/styles/fonts/ds_digital/Digital-Numbers.ttf.woff') format('woff'),
         url('../src/styles/fonts/ds_digital/DS-Digital-Bold.ttf') format('truetype'),
         url('../src/styles/fonts/ds_digital/Digital-Numbers.ttf.svg#Digital-Numbers') format('svg');
        
}

$font-family-countdown: 'Odibee Sans';
$font-family-digital: 'DS-Digtal';
$color-time-departed:#e01693;
$color-time-party: #ffe533;
.time-departed {
    color: $color-time-departed;
    h1,.h1 {
        font-family: $font-family-sans-serif;
        margin-bottom: $grid-gutter-width !important;
    }
    .count-number,.text,.ampm-departed {
        text-shadow: 0 -1px 8px $color-time-departed;
    }
    .ampm-departed {
        color: $color-time-departed;
    }
}
.present-time {
    color: $primary;
    .wrapper span:not(.bg-num),.ampm {
        text-shadow: 0 -1px 8px $primary;
    }
    .ampm {
        color: $primary;
    }
}
.party-time {
    color: $color-time-party;
    .count-number,.text,.ampm-target {
        text-shadow: 0 -1px 8px $color-time-party;
    }
    .ampm-target {
        color: $color-time-party;
    }
}

.time-departed,.present-time,.party-time {
    font-family: $font-family-digital;
   
    @media screen and (max-width: 430px) {
        .col {
            padding:0 $grid-gutter-width * 0.25;
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .month,.day,.year,.hour,.min {
        position: relative;
        font-size: $font-size-base * 5.5;
        background: rgba($white,0.1);
        max-height: $font-size-base * 7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .5rem;
        max-width: max-content;
        
        &::before {
            color: rgba($white,0.02);
            margin-top:-4px;
            padding: 0 .25rem;

        }

        @include media-breakpoint-down (xl) {
            font-size: $font-size-base * 3.25;
            max-height: 5rem;
        }
        @include media-breakpoint-down (lg) {
            font-size: $font-size-base * 2.75;
            max-height: 5rem;
        }
        @include media-breakpoint-down (md) {
            // font-size: $font-size-base * 1.75;
            font-size: $font-size-base * 1.7;
            border-radius: .25rem;
            // max-height: 4rem;
        }
        @include media-breakpoint-down (sm) {
            // font-size: $font-size-base * 1.3;
            font-size: $font-size-base * 1.15;
            border-radius: .15rem;
            &::before {
                color: rgba($white,0.02);
                margin-top:0;
            }
        }
        @include media-breakpoint-down (xs) {
            // font-size: $font-size-base * 0.95;
            font-size: $font-size-base * 0.9;
            max-height: 3rem;
        }

        @media screen and (max-width: 450px) {
            font-size: $font-size-base * .65;
            max-height: 2rem;
        }
    }

    .month {
        // width:305px;
        &::before {
            content:"BBB";
        }
    }

    .day,.hour,.min {
        &::before {
            content:"88";
        }

        @include media-breakpoint-down(xl) {
            margin:0 .5rem;
        }
        @include media-breakpoint-down(sm) {
            margin:0;
        }
    }
    .year {
        &::before {
            content:"8888";
        }
    }

    .hour {
        @include media-breakpoint-down(xl) {
            align-self: flex-end;
        }
    }
    .min {
        align-self: flex-start;
    }
    .date-label,.ampm,.ampm-departed,.ampm-target {
        font-family: $font-family-countdown;
        font-size: $font-size-base *1.375;
        text-align: center;

        @include media-breakpoint-down (sm) {
            font-size: $font-size-base * 0.75;
        }
    }
    .date-label {
        color:$white;
    }
    .label-min {
        margin-left: -3.5rem;
    }

    .ampm,.ampm-departed,.ampm-target {
        position: absolute;
        right:0;
        top:50%;
        text-transform: uppercase;
    }
    .count-number,#present-month,#present-day,#present-year,#present-hour,#present-min,.text {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        margin-top:-4px;
    }

    .time-headline {
        font-family: $font-family-countdown;
        font-size: $font-size-base * 2.5;
        color:$white;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: $grid-gutter-width * 2.3333;

        @include media-breakpoint-down (md) {
            font-size: $font-size-base * 2;
        }
        @include media-breakpoint-down (sm) {
            font-size: $font-size-base * 1.5;
            margin-bottom: $grid-gutter-width * 1.75;
        }
        @include media-breakpoint-down (xs) {
            font-size: $font-size-base * 1.25;
            margin-bottom: $grid-gutter-width * 1.5;
        }
    }
    @include media-breakpoint-down (xl) {
        .count-number,#present-month,#present-day,#present-year,#present-hour,#present-min,.text {
            margin-top: -2px;
        }
        .month,.day,.year,.hour,.min {
            &::before {
                margin-top: -2px;
            }
        }
        .label-hour {
            margin-left:1.25rem;
        }
        .label-min {
            margin-left: -1rem;
        }
    }
    @include media-breakpoint-down(lg) {
        .ampm,.ampm-departed,.ampm-target {
            right:-15px;
        }
        .label-hour {
            margin-left:1rem;
        }
        .label-min {
            margin-left: -.5rem;
        }
    }
    @include media-breakpoint-down(md) {

        h1,.h1 {
            padding-top:$grid-gutter-width * 2;
        }
        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .label-hour,.label-min {
            margin-left:0rem;
        }
        .ampm,.ampm-departed,.ampm-target {
            right:-10px;
        }
    }
    @include media-breakpoint-down(sm) {
        .count-number,#present-month,#present-day,#present-year,#present-hour,#present-min,.text {
            margin-top: 0;
        }
        .month,.day,.year,.hour,.min {
            &::before {
                margin-top: 0;
            }
        }
    }
    @include media-breakpoint-down(xs) {

        display: flex;
        flex-direction: row;
        justify-content: center;
        .col {
            width: auto;
            flex: none;
        }
        .day {
            margin-right:0px;
        }
        .time-departed,.present-time,.party-time {
            &::before {
                padding: 0 .5rem;
            }
        }
       .hour,.min {
           align-self: center;
       }
    //    .ampm,.ampm-departed,.ampm-target {
    //     right:2px;
    //     }
    }
    @media screen and (max-width: 500px) {
        .time-departed,.present-time,.party-time {
            &::before {
                padding: 0 .35rem;
            }
        }
        .ampm,.ampm-departed,.ampm-target {
            font-size: 10px;
            top:5px;
            right:2px;
        }
    }
}

