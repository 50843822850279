@function arrow-svg($color) {
  @return inline-svg(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 40">\
      <path stroke="#{$color}" fill="transparent" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 10L40 30 L70 10" />\
    </svg>'
  );
}

#scroll-hint {
  pointer-events: none;
  @include font-size(0.5rem);
  &::before {
    content: '';
    width: 6em;
    margin-left: -3em;
    height: 3em;
    position: absolute;
    top: calc(100vh - 7em);
    z-index: 10;
    left: 50%;
    background-size: contain;
    animation: nudge 5s infinite ease-in-out;
  }

  &.white::before {
    background-image: arrow-svg(#fff);
  }

  &.black::before {
    background-image: arrow-svg(#000);
  }
}

@keyframes nudge {
  80% {
    transform: translateY(0);
  }
  85% {
    transform: translateY(15%);
  }
  90% {
    transform: translateY(0);
  }
  95% {
    transform: translateY(20%);
  }
  100% {
    transform: translateY(0);
  }
}
