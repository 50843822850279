@mixin resonsive-value($values) {
  @each $bp, $value in $values {
    $infix: breakpoint-infix($bp, $grid-breakpoints);

    @include media-breakpoint-up($bp) {
      @content ($value, $infix);
    }
  }
}

#page-wrap {
  overflow: hidden;
  width: 100%;
}

$section-padding-max: $spacer * 6.25;
$section-paddings: (
  xs: $section-padding-max * 0.5,
  sm: $section-padding-max * 0.6,
  md: $section-padding-max * 0.7,
  lg: $section-padding-max * 0.8,
  xl: $section-padding-max * 0.9,
  xxl: $section-padding-max
);

@include resonsive-value($section-paddings) using ($spacer, $infix) {
  section {
    padding-top: $spacer;
    padding-bottom: $spacer;
  }

  @each $prop, $abbrev in (margin: m, padding: p) {
    .#{$abbrev}-section {
      #{$prop}: $spacer !important;
    }
    .#{$abbrev}t-section {
      #{$prop}-top: $spacer !important;
    }
    .#{$abbrev}b-section {
      #{$prop}-bottom: $spacer !important;
    }
    .#{$abbrev}y-section {
      #{$prop}-top: $spacer !important;
      #{$prop}-bottom: $spacer !important;
    }
    .#{$abbrev}l-section {
      #{$prop}-left: $spacer !important;
    }
    .#{$abbrev}r-section {
      #{$prop}-right: $spacer !important;
    }
    .#{$abbrev}x-section {
      #{$prop}-left: $spacer !important;
      #{$prop}-right: $spacer !important;
    }
  }

  .m-nsection {
    margin: -$spacer !important;
  }
  .mt-nsection {
    margin-top: -$spacer !important;
  }
  .mb-nsection {
    margin-bottom: -$spacer !important;
  }
  .my-nsection {
    margin-top: -$spacer !important;
    margin-bottom: -$spacer !important;
  }
  .ml-nsection {
    margin-left: -$spacer !important;
  }
  .mr-nsection {
    margin-right: -$spacer !important;
  }
  .mx-nsection {
    margin-left: -$spacer !important;
    margin-right: -$spacer !important;
  }
}

section {
  // padding: #{map-get($spacers, section)} 0;
  background-size: cover;
  background-position: center;
}

section:not([class*='bg-']):not(.pb-0):not(.py-0)
  + section:not([class*='bg-']) {
  padding-top: 0;
}
@each $name, $_ in $color-schemes {
  section.bg-#{$name}:not(.pb-0):not(.py-0) + section.bg-#{$name} {
    padding-top: 0;
  }
}

#page-wrap > section:first-child,
#page-wrap > div > section:first-child,
#page-wrap > div > div > section:first-child {
  padding-top: $section-padding-max;
}

.pixel {
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    flex: 0 0 0px;
  }
}
$ratios: (
  '16-9': 9 / 16,
  'thumbnail': 113 / 170,
  'logo': 1 / 2,
  'portrait': 4/3
);
@each $name, $ratio in $ratios {
  .ratio-#{unquote($name)} {
    @extend .pixel;
    &::before {
      padding-bottom: $ratio * 100%;
    }
  }
}

@mixin create-container(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @include make-container();
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.container-narrow {
  @include create-container($container-narrow-max-widths);

  // TODO: Wenn nicht genug Platz: Volle Breite
}

.container,
.container-narrow,
.container-fluid {
  .container,
  .container-narrow,
  .container-fluid {
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
  }
}

$cols-counts: (1, 2, 3, 4, 5, 6, 7, 12);
@each $name, $_ in $grid-breakpoints {
  @include media-breakpoint-up($name) {
    @each $count in $cols-counts {
      $width: 1 / $count * 100%;
      $card-width: calc(#{$width} - #{$grid-gutter-width});
      $card-width-deck: calc(#{$width} - #{$card-columns-gap});
      $infix: breakpoint-infix($name, $grid-breakpoints);
      .cols#{$infix}-#{$count} {
        > * {
          flex: 0 0 #{$width};
          min-width: $width;
          width: $width;
        }
        > .card {
          flex: 0 0 #{$card-width};
          min-width: $card-width;
          width: $card-width;
        }
      }
      .card-deck.cols#{$infix}-#{$count} {
        > .card {
          flex: 0 0 #{$card-width-deck};
          min-width: $card-width-deck;
          width: $card-width-deck;
        }
      }
    }
  }
}

[class*='cols-'] > .card {
  margin-left: $grid-gutter-width / 2;
  margin-right: $grid-gutter-width / 2;
}

.card-deck[class*='cols-'] {
  margin-left: -$card-columns-gap / 2;
  margin-right: -$card-columns-gap / 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > .card {
    margin-left: $card-columns-gap / 2;
    margin-right: $card-columns-gap / 2;
  }
}

.row {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  > * {
    & > :last-child {
      margin-bottom: 0;
    }

    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

section.video-bg {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // IE11
    height: 100vh;
  }

  &:focus {
    outline: none;
  }

  .animated-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    &.js-pointwave {
      bottom: -100px;
      &::after {
        content: '';
        position: absolute;
        height: 100px;
        left: 0;
        right: 0;
        bottom: 0;
      }

      @include theme-colors using ($colors) {
        background-color: map-get($colors, background);
        &::after {
          background: linear-gradient(
            rgba(map-get($colors, background), 0),
            rgba(map-get($colors, background), 1)
          );
        }
      }
    }
  }
  video.bg-video {
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: -1;
  }
  &.bg-black,
  &.bg-white {
    background-color: transparent !important;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #000;
      z-index: -2;
    }
  }
  &.bg-white::before {
    background-color: #fff;
  }
}

section.full-height {
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  display: flex;
}
