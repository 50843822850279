.js-pointcloud {
  height: 100vh;
  position: relative;

  canvas:focus {
    outline: none;
  }

  .progressbar {
    width: 300px;
    height: 10px;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -5px;
    border: 1px solid #fff;
    background-color: #000;
    padding: 1px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;

    &.show {
      opacity: 1;
    }

    > div {
      height: 100%;
      background-color: #fff;
      width: 0;
    }
  }
}
