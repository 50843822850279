body.menu-open {
  overflow: hidden;
}

#page-header {
  .logo {
    position: fixed;
    z-index: $zindex-fixed + 1;
    top: 3rem;
    right: 2 * $page-border-width;
    img {
      width: auto;
      height: auto;
    }
    @include media-breakpoint-down(xs) {
      transform: scale(0.85);
      right: 2rem;
      top: 2rem;
      img {
        height: 2rem;
        width: auto;
      }
    }
  }

  &.pmd-3d {
    .logo {
      top: 0;

      @include media-breakpoint-down(xs) {
        transform: scale(1);
        top: 0;
        img {
          height: 3.75rem;
        }
      }
    }
  }
}
body:not(.menu-open) #page-header {
  .logo {
    mix-blend-mode: exclusion;
  }
}
#mainmenu {
  .sidebar a,
  .sidebar .a {
    color: #000;
    transition: color 0.2s;
  }

  svg path {
    transition: fill 0.2s;
  }

  .sidebar {
    &::before {
      content: '';
      position: fixed;
      width: $page-border-width;
      top: 0;
      left: 0;
      left: env(safe-area-inset-left);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 0;
      }
      @supports (-ms-ime-align: auto) {
        left: 0;
      }
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.4s;
      @include gradient(mirrorGradient($gradient-blue), 'to top');
      @each $gradient-name, $gradient-definition in $gradients {
        .gradients-#{$gradient-name} & {
          @include gradient(mirrorGradient($gradient-definition), 'to top');
        }
      }
    }

    &:not(.no-hover):hover::before {
      opacity: 1;
    }
    &:not(.no-hover):focus-within::before {
      opacity: 1;
    }

    .btn-menu {
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      left: env(safe-area-inset-left);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 0;
      }
      @supports (-ms-ime-align: auto) {
        left: 0;
      }
      width: $page-border-width;
      height: $page-border-width;
      z-index: $zindex-fixed + 1;
      height: 100%;
      &:focus {
        box-shadow: none;
      }

      img,
      > svg {
        position: absolute;
        left: ($page-border-width - 18px) / 2;
        top: 3rem;
        width: 18px;
        height: auto;
        @include media-breakpoint-down(xs) {
          top: 2rem;
        }
      }
      .lottie-icon {
        position: absolute;
        left: 0;
        top: 3rem;
        margin: $page-border-width / 2 - 24px;
        width: 48px;
        height: 48px;
        @include media-breakpoint-down(xs) {
          top: 2rem;
        }

        svg g path[fill] {
          @include theme-colors using ($colors) {
            fill: map-get($colors, headings);
          }
        }
      }
    }

    .sidebar-links {
      list-style: none;
      z-index: $zindex-fixed + 1;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      text-transform: uppercase;
      font-size: $small-font-size;
      display: flex;
      flex-direction: row;
      transform-origin: 0 0;
      transform: rotate(-90deg);
      position: fixed;
      bottom: -$page-border-width;
      left: 0;
      left: env(safe-area-inset-left);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: 0;
      }
      @supports (-ms-ime-align: auto) {
        left: 0;
      }

      a,
      .a {
        display: block;
        line-height: $page-border-width;
        padding: 0 1rem;
        text-decoration: none;
      }
    }
  }

  .menu-wrap {
    position: fixed;
    z-index: $zindex-fixed;
    padding-left: $page-border-width;
    padding-right: $page-border-width;
    padding-top: 3rem;
    padding-bottom: 3rem - $card-spacer-y;
    top: 0;
    bottom: 0;
    right: 100%;
    width: 100%;
    background: gradient($gradient-blue, 45deg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;

    @each $gradient-name, $gradient-definition in $gradients {
      .gradients-#{$gradient-name} & {
        background: gradient($gradient-definition, 45deg);
      }
    }
    overflow: auto;
    -webkit-overflow-scrolling: auto;
    visibility: hidden;
    transform: translateX(0%);
    transition: transform 0.4s ease-in, visibility 0.4s ease-in;

    body.menu-open & {
      visibility: visible;
      transform: translateX(100%);
      transition: transform 0.5s ease-out, visibility 0.5s ease-out;
    }

    .active,
    .active a {
      color: $primary;
      background-size: 100% $underline-thickness;
    }

    .card-deck {
      margin-bottom: -1.5rem;
    }
  }

  .mainmenu {
    &,
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    > li {
      @include font-size($h2-font-size);
      font-weight: bold;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: -0.3em;

      > * {
        flex-shrink: 0;
        align-items: flex-end;
        max-width: 100%;
      }

      > div {
        margin-bottom: 0.5em;
        margin-right: 1rem;
      }

      > ul {
        display: flex;
        flex-direction: column;
        margin-top: -0.5em;
        margin-bottom: 0.5em;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        flex: 0 0 100%;
        align-items: flex-start;

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          flex-wrap: wrap;
          flex: 1 0 auto;
          align-self: flex-end;
          align-items: flex-end;

          &::before {
            content: '';
            display: block;
            width: 5rem;
            background-color: #fff;
            height: 1px;
            margin: 0 0.5rem 0.35rem 0.5rem;
            align-self: flex-end;
          }
        }

        li {
          @include font-size(1rem);
          display: block;
          padding: 0 0.5rem;
        }
      }
    }
  }
}

body.bg-black #mainmenu .sidebar,
body.menu-open #mainmenu .sidebar,
#mainmenu .sidebar:not(.no-hover):hover {
  .btn-menu > svg path {
    fill: #fff;
  }

  .lottie-icon svg g path[fill] {
    fill: #fff !important;
  }

  a,
  .a {
    color: #fff;
  }
}

#mainmenu .sidebar:focus-within {
  .btn-menu > svg path {
    fill: #fff;
  }

  .lottie-icon svg g path[fill] {
    fill: #fff !important;
  }

  a.a {
    color: #fff;
  }
}
