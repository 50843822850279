.card,
.rollover-wrap {
  position: relative;

  .rollover-show,
  .rollover-hide {
    transition: opacity 0.6s ease-in-out;
    opacity: 0;
  }

  .rollover-hide {
    opacity: 1;
  }

  .rollover-hide-delayed {
    transition: opacity 0s;
    transition-delay: 0.6s;
    opacity: 1;
  }

  &:hover .rollover-show {
    opacity: 1;
  }

  &:hover .rollover-hide,
  &:hover.rollover-hide-delayed {
    transition-delay: 0s;
    opacity: 0;
  }
}

.card-body {
  padding: #{$card-spacer-y} #{$card-spacer-x};

  > :last-child {
    margin-bottom: 0;
  }
}

.card-footer {
  padding-bottom: 0;
  background: transparent;
}

.card-deck {
  > .card {
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 3rem;
    }
  }

  margin-bottom: -2rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: -3rem;
  }
}

@include theme-colors(true) using ($colors) {
  .blog-archive {
    .card {
      background: map-get($colors, background);
    }
  }
}
