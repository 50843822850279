body.animated {
  .scroll-zoom-image {
    overflow: hidden;
    > img,
    .zoom-img {
      transform: scale(
        calc(
          1.2 - var(--visible-in-pct) * 0.2 + var(--visible-pct) * 0.05 + 0.05
        )
      );
      transform-origin: bottom center;
      will-change: transform;
      transform-style: preserve-3d;
    }
  }

  .scroll-fade-in {
    opacity: calc(
      var(--visible-in-pct) * 0.25 + var(--visible-in-center-pct) * 0.75
    );
  }

  .visibility-fade-in,
  .content-text p,
  .content-text li,
  .content-text h1,
  .content-text h2,
  .content-text h3,
  .content-text h4,
  .content-text h5,
  .content-text h6 {
    opacity: 0;
    transition: none;
    &.is-visible {
      opacity: 1;
      transition: opacity 1s;
    }
    &.was-visible {
      opacity: 1;
    }
  }
}

#mainmenu {
  .mainmenu > li {
    transform: translateX(-50%);
    transition-property: transform;
    transition-timing-function: ease-out;
    transition-delay: 0.4s;
    transition-duration: 0s;

    .menu-open & {
      transform: translateX(0);
      transition-property: transform;
      transition-timing-function: ease-out;
      transition-delay: 0s;
      transition-duration: 0.5s;
      + li {
        transition-duration: 0.6s;
      }
      + li + li {
        transition-duration: 0.7s;
      }
      + li + li + li {
        transition-duration: 0.8s;
      }
      + li + li + li + li {
        transition-duration: 0.9s;
      }
    }
  }

  .menu-wrap > :last-child {
    transform: translateY(50%);
    opacity: 0;
    transition: transform 0s, opacity 0s;
    transition-delay: 0.4s;

    .menu-open & {
      transform: translateY(0);
      opacity: 1;
      transition: transform 0.6s ease-out, opacity 0.6s;
      transition-delay: 0.6s;
    }
  }
}
