.marquees-container {
    display: flex;
    align-items: center;
    aspect-ratio: 21 / 9;
}
.marquees {
width: 100%;
overflow: hidden;
white-space: nowrap;
    &:hover &__item { animation-play-state: paused; }

    &__item {
        display: inline-block;
        will-change: transform;
        animation: marquee 40s linear infinite;
        font
        a {
            color: $white;
            text-decoration: none;
        }
    }
}

@keyframes marquee {
    0%   { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}


// Styling
.marquees {
    padding: .5rem 0;
    color: #fff;
}