body.tilt3d-enabled {
  .tilt3d {
    position: relative;
    //Safari-Bug
    //transform-style: preserve-3d;
    z-index: 10;
  }

  .tilt3d,
  .tilt3d-shadow {
    // transform: perspective(600px) translate3d(0, 0, 0);
    backface-visibility: visible;
    will-change: transform;
    position: relative;
    box-shadow: none !important;

    &:focus {
      outline: none;
    }
  }

  .tilt3d:not(.tilt3d-ns) {
    background-color: #fff;
  }
  .tilt3d.tilt3d-ns {
    .tilt3d-shadow {
      display: none;
    }
  }

  a.tilt3d {
    display: inline-block;
  }

  .tilt3d-shadow {
    mix-blend-mode: screen;
    transform: translateZ(-10px);
  }

  .tilt3d-shadow {
    background-color: rgba($primary2, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;

    .bg-black &,
    .bg-gradient & {
      background-color: rgba($primary2, 0.5);
    }
  }

  // IE Fix
  .tilt3d:not(.pixel)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: inherit;
    z-index: -1;
    footer & {
      background: #fff;
    }
  }
}
