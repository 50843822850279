@import '~swiper/swiper';

.swiper-container {
  user-select: none;
}

.swiper-container-fade {
  @include theme-colors using ($colors) {
    &,
    .swiper-slide {
      background-color: map-get($colors, background);
    }
  }
}

.slider-double {
  position: relative;

  .content-slider {
    .swiper-slide {
      padding: 1.5 * $card-spacer-y;
      pointer-events: none;

      &.swiper-slide-active {
        pointer-events: all;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .content-slider {
      position: absolute;
      bottom: 1.5 * $card-spacer-y;
      right: 1.5 * $card-spacer-y;
      z-index: 2;
      width: 50%;
      max-width: 800px;
    }

    .swiper-navigation {
    }
  }

  @include media-breakpoint-up(xl) {
    .content-slider {
      bottom: 3 * $card-spacer-y;
      right: 3 * $card-spacer-y;
    }
  }

  @include media-breakpoint-up(xxl) {
    .content-slider {
      bottom: 4 * $card-spacer-y;
      right: 4 * $card-spacer-y;
    }
  }
}

.swiper-slide [class*='ratio-'] {
  max-height: 40rem;
}

.slider-thumnails {
  position: relative;
  @include font-size(1rem);

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 1s;
  }

  .swiper-slide-active .bg-image,
  .swiper-slide-duplicate-active .bg-image {
    opacity: 1;
  }

  .thumbs-slider {
    margin: -50px 0;

    .thumbnail {
      margin: 50px #{$grid-gutter-width / 2};
      font-weight: bold;
      @include theme-colors using ($colors) {
        color: map-get($colors, headings);
      }
      @include font-size(0.8rem);
      .thumbnail-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0.75rem 1rem;
        background-position: center;
        background-size: cover;
        > div {
          max-height: 3em;
        }
      }
    }
  }

  .slider-thumnails-nav {
    position: relative;
    width: calc(100% + #{$grid-gutter-width});
    max-width: 35rem;
    min-width: 30rem;
    display: flex;
    flex-direction: row;
    z-index: 1;
    align-items: flex-start;
    margin: #{$grid-gutter-width / 2} #{-$grid-gutter-width / 2} 0 0;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      right: -$grid-gutter-width;
      bottom: 1.5 * $card-spacer-y;
      position: absolute;
    }

    .btn-group {
      flex-shrink: 0;
      .btn {
        margin-right: $grid-gutter-width / 2;
      }
    }
    @include media-breakpoint-up(xl) {
      bottom: 2 * $grid-gutter-width;
    }
    @include media-breakpoint-up(xxl) {
      bottom: 2.5 * $grid-gutter-width;
      max-width: 40rem;
    }
  }
}

.swiper-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 0 #{$grid-gutter-width / 2};
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: none;

  .btn {
    pointer-events: all;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 #{1.5 * $card-spacer-y};
  }

  @include media-breakpoint-up(xl) {
    padding: 0 #{3 * $card-spacer-y};
  }

  @include media-breakpoint-up(xxl) {
    padding: 0 #{4 * $card-spacer-y};
  }
}

.swiper-container-fade.swiper-slide {
  pointer-events: none;

  &-active {
    pointer-events: all;
  }
}

.big-teaser {
  max-height: 70vh;
  height: 55vw;
  position: relative;
  overflow: hidden;
}
