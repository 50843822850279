.pixel-content {
  padding: 1.5 * $card-spacer-y;
  position: relative;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h5,
  .h5,
  h6,
  .h6 {
    color: #000;
  }

  > :last-child {
    margin-bottom: 0;
  }
  p + .ic {
    display: block;
    margin-top: -0.5rem;
  }
}

.pixel-link {
  @include theme-colors() using ($colors) {
    color: map-get($colors, headings);
  }

  display: block;
  @include font-size(1rem);
  width: 13.5em;
  margin-bottom: 13.5em * 0.25;
  position: relative;

  &.pixel-link-sm {
    width: 100%;
    font-size: $small-font-size;
    @include media-breakpoint-down(xl) {
      @include font-size(0.6rem);
      h6 {
        @include font-size(0.7rem);
      }
    }
    .pixel-content {
      padding: 1.25 * $card-spacer-y;
    }
  }

  .bg-dot-pattern {
    position: absolute;
    bottom: -25%;
    left: -25%;
    width: 70%;
    height: 70%;
    pointer-events: none;
    transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &[href] {
    &:hover,
    &:focus {
      .bg-dot-pattern {
        transform: translateX(-10%);
      }
    }

    &:active .bg-dot-pattern {
      transform: translateX(0%);
    }
  }
}

.image-pixel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include font-size(1rem);
  align-items: flex-end;

  img {
    position: relative;
    z-index: 0;
    width: 100%;
    height: auto;
  }
  figcaption {
    margin-top: -6rem;
    position: relative;
    z-index: 2;
    margin-right: 3em;

    @include media-breakpoint-down(md) {
      margin-top: -2rem;
    }
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    figcaption {
      margin-left: 4.5em;

      @include media-breakpoint-down(lg) {
        margin-left: 3rem;
        margin-top: -5rem;
      }
    }

    img {
      width: 75%;
    }
    & + & {
      margin-top: -14em;
    }
    &:nth-child(2n) {
      align-items: flex-end;
      figcaption {
        margin-left: 0;
        margin-right: 4.5rem;
        margin-top: -9em;

        @include media-breakpoint-down(lg) {
          margin-right: 3rem;
          margin-top: -6em;
        }
      }
    }
  }
}

.bg-dot-pattern {
  position: relative;
  background-clip: content-box;
  background-size: 20px 40px;
  @include theme-gradient() using ($stops, $name) {
    background-image: inline-svg(
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 40"><defs><linearGradient id="g" x1="100%" y1="0" x2="0" y2="100%">#{svgGradientStops($stops)}</linearGradient></defs><rect x="10" y="0" width="5" height="5" fill="url(#g)" /><rect x="0" y="20" width="5" height="5" fill="url(#g)" /></svg>'
    );
  }
}

// @include media-breakpoint-down(lg) {
//   .image-pixel figcaption {
//     width: ;
//   }
// }

.half-image-pixel {
  display: flex;
  flex-direction: column;

  img {
    width: 90%;
    height: 100%;

    &.img-narrow {
      width: 80%;
    }
  }

  & + & {
    margin-top: 2rem;
  }

  @include media-breakpoint-up(md) {
    &:nth-child(2n) {
      margin-left: 25%;

      img {
        align-self: flex-end;
      }
    }
    width: 75%;
  }

  @include media-breakpoint-up(lg) {
    &:nth-child(2n) {
      margin-left: 50%;
    }
    & + & {
      margin-top: -8rem;
    }
    width: 50%;
  }

  @include media-breakpoint-up(xl) {
    & + & {
      margin-top: -10rem;
    }
  }

  @include media-breakpoint-up(xxl) {
    &:nth-child(2n) {
      margin-left: 50%;
    }
    img {
      width: 5/6 * 100%;
      &.img-narrow {
        width: 70%;
      }
    }
    & + & {
      margin-top: -12rem;
    }
    width: 50%;
  }

  .pixel-link {
    align-self: flex-end;
    margin-top: -7em;

    @include media-breakpoint-down(xs) {
      margin-right: 0.5rem;
    }

    @include media-breakpoint-up(xxl) {
      margin-top: -10em;
      margin-right: 1/12 * 100%;
    }
  }
}

// To be used with .pixel or .ratio-*
// All Images and videos are assumed to have the same aspect-ratio
.img-stack {
  position: relative;

  > img,
  > video,
  > .lottie {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
