body {
  counter-reset: section-header;
}

@function heading($size) {
  @return (unquote('h#{$size}'), unquote('.h#{$size}'));
}

@function headings($from: 1, $to: 6) {
  $result: ();
  @each $size in (1, 2, 3, 4, 5, 6) {
    @if $size >= $from {
      @if $size <= $to {
        $result: join($result, heading($size));
      }
    }
  }
  @return $result;
}

.counter-reset {
  counter-reset: any-counter;
}
.counter {
  counter-increment: any-counter;
  &::before {
    content: counter(any-counter, decimal-leading-zero);
  }
}

.section-header {
  font-size: 0.6rem;
  font-weight: normal;
  line-height: 1.3;
  letter-spacing: 0.9;
  // position: relative;
  // For Edge
  @supports (-ms-ime-align: auto) {
    display: inline;
    &::after {
      content: '';
      display: block;
    }
  }

  &::before {
    content: '';
    $line-width: 6.5rem;
    $total-width: 6.5rem;
    $line-margin: ($total-width - $line-width);
    padding-right: $line-width + $line-margin + 1.5rem;
    background-size: #{$line-width} 1px;
    display: inline;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: left center;
    margin-right: $line-margin;
    background-repeat: no-repeat;
    flex-shrink: 0;

    @supports (-webkit-hyphens: none) {
      // For Safari
      display: block;
      float: left;
    }

    @include media-breakpoint-down(xs) {
      $line-width: 4rem;
      $total-width: 6rem;
      $line-margin: ($total-width - $line-width) / 2;
      padding-right: $line-width + $line-margin;
      background-size: #{$line-width} 1px;
      margin-right: $line-margin;
    }
  }

  &--enumerated {
    counter-increment: section-header;
    &::before {
      content: counter(section-header, decimal-leading-zero);
      $line-width: 6.5rem;
      $total-width: 9.5rem;
      $line-margin: ($total-width - $line-width) / 2;
      padding-right: $line-width + $line-margin;
      background-size: #{$line-width} 1px;
      background-position: right center;
      margin-right: $line-margin;
    }
  }

  h1 &,
  .h1 & {
    margin-bottom: 1.5rem;
  }

  h2 &,
  .h2 & {
    margin-bottom: 1rem;
  }
}

#{heading(6)} {
  line-height: $line-height-base;
}

#{headings()} {
  // This prevents Chrome from cutting of text
  padding-bottom: 0.05em;
}

$underline-thickness: 0.11em;
$use-underline: (
  '#mainmenu .menu-wrap',
  '.content-text',
  '.card-body',
  '.card-footer',
  '.footer-menu',
  '.content-slider',
  'figcaption',
  '.footer-small-menu'
);
@function descendent-of($selectors, $selector) {
  $result: ();
  @each $item in $selectors {
    $result: append(
      $result,
      unquote('#{$item} #{$selector}:not(.btn):not(.plain)'),
      $separator: comma
    );
  }
  @return $result;
}

#{descendent-of($use-underline, a)},
a.hover-underline,
.content-text u,
.hover-underline {
  // background-color: rgba($blue, 0.5);
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0 $underline-thickness;
  background-clip: border-box;
  padding-bottom: 0.15em;
  margin-bottom: -0.15em;
  // You need to transition color and transform sub one frame to make it work in Chrome
  transition: background-size 0.4s, color 0.01s, transform 0.01s;
  will-change: background-size;
  transform: translateZ(0);
}

.content-text u {
  background-size: 100% $underline-thickness;
  text-decoration: none;
}

a {
  &:hover,
  &:focus,
  &.active {
    .hover-underline {
      background-size: 100% $underline-thickness;
      text-decoration: none;
    }
  }
  &:hover .hover-underline {
    transform: translateZ(1px);
  }
  &:focus .hover-underline {
    transform: translateZ(2px);
  }
  &.active .hover-underline {
    transform: translateZ(3px);
  }
}

#{descendent-of($use-underline, 'a:not(.card)')},
a.hover-underline {
  &:hover,
  &:focus,
  &.active {
    background-size: 100% $underline-thickness;
    text-decoration: none;
  }
  &:hover {
    transform: translateZ(1px);
  }
  &:focus {
    transform: translateZ(2px);
  }
  &.active {
    transform: translateZ(3px);
  }
}

.big-quote {
  cite {
    font-style: normal;
  }

  footer {
    margin-top: 2rem;
    line-height: $headings-line-height;
  }
}

.big-quote-content {
  @include font-size($h2-font-size);
  font-weight: bold;
  line-height: $headings-line-height;
  white-space: nowrap;

  > * {
    white-space: normal;
  }

  &::before,
  &::after {
    content: '„';
    font-size: 206%;
    vertical-align: 0.086em;
    margin-top: -0.75em;
  }

  &::before {
    display: block;
    line-height: 1.2;
  }

  &::after {
    display: inline-block;
    // Remove any effect on the layout of the quote
    line-height: 0;
  }

  &.size-h3 {
    @include font-size($h3-font-size);
  }

  > p {
    @include theme-colors using ($colors) {
      color: map-get($colors, headings);
    }
    &:last-child {
      display: inline;
    }
  }
}

#{headings()} {
  .content-text p + &,
  .content-text ol + &,
  .content-text ul + & {
    margin-top: 2rem;
  }
}

ul.fancy,
.content-text ul {
  list-style-type: none;
  padding-left: 1.5em;
  li {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: -1em;
      top: 0.56em;
      width: 0.4em;
      height: 0.4em;
      background: $blue;
      box-shadow: 0.15em 0.15em 0 0 rgba($primary2, 0.5);
    }
  }
}

.blog-nav-link {
  text-transform: uppercase;
  font-weight: bold;
  font-size: $small-font-size;
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);
  }
}

.text-small {
  font-size: $small-font-size;
}

body {
  @include font-size(1rem);
}

h1,
.h1,
.display-1,
.lh-1 {
  line-height: 1.1;
}
