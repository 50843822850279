.btn {
  -webkit-appearance: none;
  text-transform: uppercase;
  font-weight: bold;
  white-space: nowrap;
  flex-shrink: 0;
}

.btn-primary {
  color: #fff !important;
}

.btn-icon {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 2.33rem;
}

label {
  font-size: $small-font-size;
  transition: color 0.15s ease-in-out;
}

label:not(.custom-control-label):active,
label:not(.custom-control-label):focus {
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);
  }
}

.form-group:focus-within label:not(.custom-control-label) {
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);
  }
}

.form-group label + .form-control {
  padding-top: 0rem;
}

.form-group {
  &.is-invalid {
    > .invalid-feedback {
      display: block;
    }
  }
}

.form-control {
  border-bottom: 1px solid transparent;
  box-shadow: none !important;
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);
    border-color: map-get($colors, border);

    &:focus {
      border-color: map-get($colors, headings);
    }
  }
  &.bootstrap-select {
    .btn {
      text-transform: none;
      //padding: 0.6rem 1rem;
    }
    .btn-light {
      background-color: transparent !important;
      border: 1px solid transparent;
      box-shadow: none !important;
      @include theme-colors using ($colors) {
        color: map-get($colors, headings);
        border-color: map-get($colors, border);
      }
      &:focus, &.focus {
        @include theme-colors using ($colors) {
          border-color: map-get($colors, headings);
        }
      }
    }
    &.show {
      .btn-light {
        @include theme-colors using ($colors) {
          border-color: map-get($colors, headings);
        }
      }
    }
    .dropdown-menu {
      font-size: inherit;
    }
    > .dropdown-menu {
      font-size: inherit;
      border: 1px solid transparent;
      @include theme-colors using ($colors) {
        color: map-get($colors, headings);
        border-color: map-get($colors, headings);
        background-color: map-get($colors, background);
      }
      .dropdown-menu {
        background-color: transparent;
      }
    }
    .dropdown-item {
      @include theme-colors using ($colors) {
        color: map-get($colors, headings);
      }
      background-color: transparent;
      padding: ($btn-padding-y*0.5) $btn-padding-x;
      &:hover, &:focus, &:active {
        @include theme-colors using ($colors) {
          color: map-get($colors, link);
        }
      }
      &.active, &.selected {
        @include theme-colors using ($colors) {
          color: map-get($colors, link);
        }
      }
    }
  }
}

.custom-checkbox {
  user-select: none;
}

.control-label-radio {
  margin-bottom: ($form-group-margin-bottom*0.5);
}

.custom-control-label::after {
  border: 1px solid transparent;
}

.custom-control-label::before {
  border: 1px solid transparent;
  @include theme-colors using ($colors) {
    border-color: map-get($colors, border);
  }
}

.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  @include theme-colors using ($colors) {
    background-color: map-get($colors, headings);
  }
}

@include on-dark-bg() {
  .custom-checkbox {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: escape-svg(
          $custom-checkbox-indicator-icon-checked-dark
        );
      }
    }
  }
  .custom-radio {
    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        background-image: escape-svg(
          $custom-radio-indicator-icon-checked-dark
        );
      }
    }
  }
  .captcha {
    .captcha__reload {
      svg {
        > * {
          fill: $white;
        }
      }
    }
  }
}

.form-control.is-invalid {
  border-bottom-color: $red !important;
}

.form-group.is-invalid .custom-control-input ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: $red !important;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0.05rem;
}

.invalid-feedback {
  font-size: 0.6rem;
}

.btn.has-long-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .long-content {
    white-space: normal;
    text-align: left;
  }
}

.download-portal-license {
  margin-bottom: 1rem;
  padding: 1rem;
  max-height: 300px;
  font-size: .7rem;
  line-height: 1.2;
  background: $light;
  overflow-y: auto;
}

.form-group {
  &.has-error {
    .form-control {
      border-bottom-color: $red !important;
    }
    .error {
      color: $red;
      font-size: .6rem;
    }
  }
}
