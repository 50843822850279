.scroll-video {
  position: relative;
  height: 100vh;
  box-sizing: content-box;

  .inner-wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: hidden;

    &.fixed {
      position: fixed;
      left: $page-border-width;
      left: calc(#{$page-border-width} + env(safe-area-inset-left));
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        left: $page-border-width;
      }
      @supports (-ms-ime-align: auto) {
        left: $page-border-width;
      }
      right: $page-border-width;
      right: calc(#{$page-border-width} + env(safe-area-inset-left));
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        right: $page-border-width;
      }
      @supports (-ms-ime-align: auto) {
        right: $page-border-width;
      }
      @include media-breakpoint-down(sm) {
        right: 0;
      }
    }

    &.bottom {
      top: auto;
      bottom: 0;
    }

    .contents {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
    }
  }
}

.btn-mute-video,
.video-hint {
  position: absolute;
  bottom: 1rem;
  right: 1rem;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 2rem;
  padding: 0;

  &::before {
    content: $ic-volume;
  }
  &.is-muted::before {
    content: $ic-volume-mute;
  }
}

.btn-mute-video {
  text-shadow: 0 0 1rem rgba(white, 0.5);
  color: #000 !important;
  box-shadow: none !important;
  [data-volumecontrols='white'] ~ & {
    text-shadow: 0 0 1rem rgba(black, 0.5);
    color: #fff !important;
  }
}

.video-hint {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2.5rem;
  animation: quick-hint 0.6s ease-in-out;
  &::before {
    content: $ic-play;
  }
  &-pause::before {
    content: $ic-pause;
  }

  text-shadow: 0 0 1rem rgba(white, 0.1);
  color: #000 !important;
  box-shadow: none !important;
  [data-playpause='white'] ~ & {
    text-shadow: 0 0 1rem rgba(black, 0.1);
    color: #fff !important;
  }
}

@keyframes quick-hint {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
