.collage1,
.collage2 {
  img {
    height: auto;
  }

  .row > * {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

.collage1 {
  .img1 {
    width: 100%;
  }

  .img2 {
    width: 50%;
    margin-left: 1 / 8 * 100% !important;
    margin: -2rem 0;
    @include media-breakpoint-up(xl) {
      margin: -3rem 0;
    }
    @include media-breakpoint-up(xxl) {
      width: 40%;
      margin: -3rem 0;
    }
  }

  .img3 {
    position: relative;
    width: 8/12 * 100%;
    margin-left: 25%;
  }

  .content {
    padding-top: 1.5rem;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-bottom: 1.5rem;
      padding-left: 1.5rem;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 3rem;
      padding-left: 3rem;
    }
  }
}

.collage2 {
  .img1 {
    width: 100%;
    max-height: 100%;
  }

  .img2 {
    margin-top: -2rem;
    width: 9/12 * 100%;
    margin-left: 1.5/12 * 100%;
    @include media-breakpoint-up(lg) {
      margin-top: -3rem;
      // width: 5/12 * 100%;
      // margin-left: calc(#{6 / 12 * 100%} + #{$grid-gutter-width / 2});
    }
    @include media-breakpoint-up(xxl) {
      margin-top: -5rem;
      // width: 50%;
      // margin-left: calc(#{5 / 12 * 100%} + #{$grid-gutter-width / 2});
    }
  }

  > .row > * {
    justify-content: flex-start;
  }

  .content {
    padding-top: 1.5rem;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-right: 1.5rem;
      // height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include media-breakpoint-up(xxl) {
      padding-right: 3rem;
    }
  }
}
