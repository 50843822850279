.logo-gallery {
  margin: 0 0;

  .col {
    @include theme-colors using ($colors) {
      border-top: 1px solid #{map-get($colors, border)};
    }
    margin: 0 0;
    padding: 0 0;
  }
  .col > * {
    position: relative;
    display: block;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;

    @media (min-width: 400px) {
      padding: 1.5rem 2rem;
    }
  }
}
