@function mirrorGradient($steps) {
  $prefix: ();
  $postfix: ();
  @each $pct, $color in $steps {
    @if $pct != 0% {
      $prefix: map-merge((-1 * $pct: $color), $prefix);
    }
    @if $pct != 100% {
      $postfix: map-merge((200% - 1 * $pct: $color), $postfix);
    }
  }
  $result: map-merge($prefix, map-merge($steps, $postfix));
  @return $result;
}

@function gradient($stops, $direction: 'to right') {
  $result: unquote('#{$direction}');
  @each $pct, $color in $stops {
    $result: append($result, unquote(',#{$color} #{$pct}'));
  }
  @return unquote('linear-gradient(#{$result})');
}

@function svgGradientStops($stops) {
  $result: unquote('');
  @each $pct, $color in $stops {
    $result: unquote(
      '#{$result}<stop stop-color="#{$color}" offset="#{$pct/100%}"/>'
    );
  }
  @return $result;
}

@function gradientUsingOffset($stops, $direction: 'to right') {
  $result: unquote('#{$direction}');
  @each $pct, $color in $stops {
    $varname: 'panx';
    @if ($direction == 'to bottom') or ($direction == 'to top') {
      $varname: 'pany';
    }
    $pos: unquote(
      'calc(#{$pct / 100%} * var(--gradient-width, 100%) + var(--gradient-offset, 0px) + var(--gradient-#{unquote($varname)}, 0) * var(--gradient-width, 100%))'
    );
    $result: append($result, unquote(',#{$color} #{$pos}'));
  }
  @return unquote('linear-gradient(#{$result})');
}

@mixin gradient($stops, $direction: 'to right', $property: 'background-image') {
  #{$property}: gradient($stops, $direction);
  #{$property}: gradientUsingOffset($stops, $direction);
  // @supports (-ms-ime-align: auto) {
  //   #{$property}: gradient($stops, $direction);
  // }
}
