.rating-outer {
    white-space: nowrap;
    display: block;
    height: 100%;
    .square {
        position: relative;
        background-color: $blue;
        display: inline-block;
        width: 0.75rem;
        vertical-align: middle;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-top: 100%;
        }
        & + .square {
            margin-left: 0.25rem;
        }
    }
} 

.table-scroll-arrow-outer {
    .table-scroll-arrow-wrapper {
        .scroll-arrow {
            margin-bottom: 0.25rem;
        }
        .scroll-text {
            font-weight: $font-weight-normal;
        }
    }
}

table.table-rating {
    > tbody {
        > tr {
            &:first-child {
                td, th {
                    vertical-align: bottom;
                }
            }
        }    
    }
    td, th {
        width: 25%;
        //&:first-child {
        //    position: sticky;
        //    left: 0;
        //    background-color: $black;
        //    z-index: 11;
        //}
    }
}
