.nav-tags {
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);

    .nav-link {
      color: inherit;

      &.active {
        color: $blue;
      }
    }
  }
  @include font-size($h5-font-size);
  font-weight: bold;
  margin: #{-$nav-link-padding-y} #{-$nav-link-padding-x};
}

.blog-meta {
  margin-bottom: 2rem;
  .nav-tags {
    font-size: inherit;
    font-weight: normal;
    color: inherit;
    margin-bottom: 0rem;
  }
}
