@include theme-colors(true) using ($colors) {

	.accordion-panel {
		border-top: 1px solid map-get($colors, border);
		.accordion__header {
			color: map-get($colors, headings);
			&:hover {
				background-color: map-get($colors, underlay);
			}
		}
		&:last-child {
			border-bottom: 1px solid  map-get($colors, border);
		}
	}
	
}

.accordion-panel {

	overflow: hidden;
	border-width: 1px 0 0 0;

	.accordion__header {
		padding: $grid-gutter-width*0.5;
		display: flex;
		background-color: transparent;
		align-items: center;
		justify-content: space-between;
        transition: background-color ease-in-out .35s;
        cursor: pointer;
		.accordion__content {
			padding-right: $grid-gutter-width*0.5;
		}
		h3, h4 {
			margin: 0;
		}
        .ic-arrow-right {
            transition: transform .35s ease-in-out;
			margin-bottom: 0;
        }
	}

	.accordion__body {
		padding: $grid-gutter-width*0.5;
		display: none;
		p:last-child {
			margin-bottom: 0;
		}
	}

}

div:not(.col-lg-6) {
	> .accordion {
		.accordion__body {
			@include media-breakpoint-up(lg) {
				> .content-text {
					width: 80%;
				}
			}
		}
	}
}