.cta-outer {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(env(safe-area-inset-right) + 44px);
    z-index: 1029;
    height: 100%;
    .btn {
        position: fixed;
        bottom: 50%;
        height: calc(env(safe-area-inset-right) + 44px);
        width: calc(env(safe-area-inset-right) + 44px);
        padding: 0;
        font-size: 24px;
        line-height: 44px;
        text-align: center;
        &:hover, &:focus, &:active {
            background: $blue;
        }
    }
}