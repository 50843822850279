.footer-menu {
  @include theme-colors using ($colors) {
    color: map-get($colors, headings);
  }

  &,
  ul {
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }

  display: flex;
  flex-direction: row;
  margin-left: $grid-gutter-width / -2;
  margin-right: $grid-gutter-width / -2;
  > li {
    min-width: 0;
    font-weight: bold;
    margin-bottom: 1.5rem;
    flex: 0 0 100%;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    @include media-breakpoint-up(sm) {
      flex: 0 0 50%;
    }
    @include media-breakpoint-up(md) {
      flex: 0 0 33%;
    }
    @include media-breakpoint-up(xl) {
      flex: 1 0 175px;
    }
  }

  ul {
    font-weight: normal;
    margin-top: 0.5em;
    margin: 0;
  }

  li {
    margin: 0.25rem 0;
    line-height: 1.3;
    margin-bottom: 0.5em;
  }
}

footer {
  .copyright {
    padding: 0.75rem 0;
    padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      padding: 0.75rem 0;
    }
    @supports (-ms-ime-align: auto) {
      padding: 0.75rem 0;
    }
    font-size: $small-font-size;
    color: #000;
    .bg-black & {
      color: #fff;
    }
  }

  .social {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 100%;
    // @include media-breakpoint-up(xxl) {
    //   justify-content: flex-end;
    // }

    .btn {
      mix-blend-mode: screen;
      margin: 0.5rem;
      flex-shrink: 0;
      flex-grow: 0;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      padding: 0 0;
    }
  }
}

ul.footer-small-menu {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  li {
    margin: 0.2em 0;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    li {
      margin: 0 0;
    }

    li:not(:first-child)::before {
      content: '|';
      margin: 0 0.5em;
    }
  }

  a {
    @include theme-colors using ($colors) {
      color: map-get($colors, headings);
    }
  }
}
