.list-group-flush {
  border: 0px solid transparent;
  border-width: 1px 0;

  .list-group-item {
    padding: $list-group-item-padding-y * 2 0;
  }
}

@include theme-colors(true) using ($colors) {
  .list-group-flush {
    border-color: map-get($colors, border);
  }

  .list-group-item {
    border-color: map-get($colors, border);
  }

  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: map-get($colors, underlay);
  }
}

.list-group-table {
  .list-group-item {
    border-left: none;
    border-right: none;
    padding: #{$list-group-item-padding-y / 2} 0;
    display: flex;
    flex-direction: row;
    color: inherit;
    flex-wrap: wrap;

    .list-group-item-icon {
      box-sizing: content-box;
      margin: 0 0 0 $list-group-item-padding-x;
      padding: #{$list-group-item-padding-y / 2} 0;
      @include media-breakpoint-down(md) {
        margin: 0 0 0 #{$list-group-item-padding-x / 2};
      }
    }

    .list-group-item-body {
      flex: 1;
      padding: #{$list-group-item-padding-y / 2} $list-group-item-padding-x;

      @include media-breakpoint-down(md) {
        padding: #{$list-group-item-padding-y / 2} #{$list-group-item-padding-x / 2};
      }

      h6 {
        margin-bottom: 0;
      }

      > :last-child {
        margin-bottom: 0;
      }
    }

    .list-group-item-actions {
      padding: #{$list-group-item-padding-y / 2} 0;
      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        padding: #{$list-group-item-padding-y / 2} #{$list-group-item-padding-x /
          2};
      }
    }
  }
}

.list-uploads {
  .list-group-item-body {
    max-width: calc(100% - 3.25rem);
    > div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.list-group-2col {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: calc(50% - #{$grid-gutter-width});
      right: $grid-gutter-width / 2;
      top: 0;
      height: $list-group-border-width;
      @include theme-colors using ($colors) {
        background-color: map-get($colors, border);
      }
    }

    > * {
      flex: 0 0 calc(50% - #{$grid-gutter-width});
      margin: 0 $grid-gutter-width / 2;
    }
  }
}

.list-group-item-icon {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;

  > .lottie {
    width: 100%;
    height: 100%;
  }

  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.list-group-lg {
  .list-group-item {
    padding: 1.5rem 0;
  }
  .list-group-item .list-group-item-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 0;
    margin-right: 1.5rem - $list-group-item-padding-x;
  }
}
