@import "@fancyapps/ui/src/Fancybox/Fancybox.scss";
@import 'bootstrap-select.scss';
@import "~bootstrap-select/sass/bootstrap-select";
$icomoon-font-path: 'styles/fonts/pmd-icons-v1.0/fonts';
@import 'fonts/pmd-icons-v1.0/style.scss';

@import 'mixins/gradients';
@import 'mixins/inline-svg';
@import 'parts/colors';
@import 'parts/grid';
@import 'parts/typography';
@import 'parts/forms';
@import 'parts/header';
@import 'parts/cards';
@import 'parts/footer';
@import 'parts/tilt3d';
@import 'parts/list-group';
@import 'parts/scroll-video';
@import 'parts/navs';
@import 'parts/shuffle';
@import 'parts/image-pixel';
@import 'parts/swiper';
@import 'parts/logo-gallery';
@import 'parts/collage';
@import 'parts/animations';
@import 'parts/usercentrics';
@import 'parts/type-alternatives';
@import 'parts/scroll-hint';
@import 'parts/cta';
@import 'parts/point-cloud';
@import 'parts/accordion';
@import 'parts/countdown';
@import 'parts/text-loop';
@import 'parts/fancybox';
@import 'parts/table';
@import 'parts/video-gallery';

::selection {
  background-color: rgba($blue, 0.5);
}

.bg-gradient,
[class*='bg-'] .bg-gradient {
  ::selection {
    background-color: rgba($primary, 0.5);
  }
}

:root {
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // @include media-breakpoint-down(xs) {
  //   font-size: 18px;
  // }
}

.parallax-image {
  position: relative;
  z-index: -1;
  img {
    max-width: 100%;
    height: auto;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  & + .row {
    transform: translateY(-70%);
    @include media-breakpoint-down(xl) {
      transform: translateY(-50%);
    }
    @include media-breakpoint-down(lg) {
      transform: translateY(-40%);
    }
    @include media-breakpoint-down(md) {
      transform: translateY(-40%);
    }
    @include media-breakpoint-down(sm) {
      transform: none;
    }
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  padding: 0 #{$page-border-width};
  padding-left: calc(env(safe-area-inset-left) + #{$page-border-width});
  padding-right: calc(env(safe-area-inset-right) + #{$page-border-width});
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding: 0 #{$page-border-width};
  }
  @supports (-ms-ime-align: auto) {
    padding: 0 #{$page-border-width};
  }
  @include media-breakpoint-down(sm) {
    padding-right: 0;
  }
}

img[data-fit] {
  position: absolute;
  width: 100%;
  height: auto;
}
