body #usercentrics-button.layout-1 {
  line-height: 1;

  .uc-banner .uc-banner-content {
    $offset: $page-border-width + $grid-gutter-width / 2;
    width: 30rem !important;
    left: auto !important;
    max-width: calc(100% - #{2 * $offset}) !important;
    right: $offset !important;
    bottom: $grid-gutter-width / 2 !important;
  }

  @media (max-width: 761.98px) {
    .uc-banner .uc-banner-content {
      max-width: calc(100% - #{$page-border-width}) !important;
      width: 100% !important;
      right: 0rem !important;
      bottom: 0rem !important;
    }
  }

  @media (max-width: 640px) {
    &.layout-1 .uc-banner .uc-banner-content {
      left: auto !important;
    }
  }

  #uc-banner-modal .uc-optin-description {
    @include font-size($small-font-size, true);
  }

  #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner,
  #uc-banner-modal
    .btn-list.show-deny-btn.show-more-btn
    #uc-btn-more-info-banner,
  #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-accept-banner {
    font-weight: bold !important;
  }

  @media (min-width: 641px) {
    .btn-list {
      margin: 0 #{$grid-gutter-width / -2} !important;
      width: calc(100% + #{$grid-gutter-width}) !important;
    }

    #uc-banner-modal .btn-list.show-deny-btn.show-more-btn #uc-btn-deny-banner,
    #uc-banner-modal
      .btn-list.show-deny-btn.show-more-btn
      #uc-btn-more-info-banner {
      margin-left: #{$grid-gutter-width / 2} !important;
      margin-right: #{$grid-gutter-width / 2} !important;
      width: calc(30% - #{$grid-gutter-width}) !important;
    }

    #uc-banner-modal
      .btn-list.show-deny-btn.show-more-btn
      #uc-btn-accept-banner {
      margin-left: #{$grid-gutter-width / 2} !important;
      margin-right: #{$grid-gutter-width / 2} !important;
      width: calc(40% - #{$grid-gutter-width}) !important;
    }
  }

  a.uc-powered-by-logo {
    background: none !important;
  }
}

.embed-responsive .uc-embedding-container {
  position: absolute;
  display: block;
  top: 0;
  left: 0;

  a {
    background: none !important;
  }
}
.uc-embedding-wrapper {
  background-color:$black !important;

  .uc-embedding-more-info {
    background:$black !important;
    color:$white;
  }
  .uc-embedding-accept {
    background:rgb(40,167,69);
  }
}
