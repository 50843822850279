.video-gallery {
    position: relative;
    .ic-play {
        position: absolute;
        top:50%;
        left:50%;
        opacity: 0;
        transition: opacity .5s ease-in-out;
        color:$blue !important;
        transform: translate(-50%, -50%);
    }

    &:hover,&:focus,&:active {
        .ic-play {
            opacity: 1;
        }
    }
}