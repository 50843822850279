[data-type-alternatives] {
  &::after {
    content: '';
    display: inline-block;
    position: relative;
    width: 0.1em;
    height: 0.9em;
    top: 0.08em;
    background-color: currentColor;
    left: 0.15em;
    animation: blink 0.75s infinite;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
